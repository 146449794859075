
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}

.btn-circle {
  width: 200px;
  height: 200px;
}

.navbar-brand img {
    max-height: 100px;
}

a,a:hover, a:focus {
    color: #f9cdd4;
    text-decoration: none;
    outline: none;
}

.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active, .show>.btn-primary.dropdown-toggle{
    background-color: #f9cdd4;
    border-color: #f9cdd4;
}

.btn-primary {
    background-color: #f9cdd4;
    border-color: #f9cdd4;
}

.btn-primary.disabled, .btn-primary:disabled {
    background-color: #f9cdd4;
    border-color: #f9cdd4;
}

.btn-primary:hover {
    color:#000;
    background-color: #f9cdd4;
    border-color: #f9cdd4;
}

.fc-header-toolbar .fc-button-primary {
    color: #fff;
    background-color: #4b4c49;
    border-color: #4b4c49;
}

.btn-resize {
    font-size: 8.5px;
    width: 85px;
  height: 85px;
}

.fc-past{
    background-color: #969696;
}